<template>
  <div>
    <CRow>
      <CCol sm="4">
        <CCard border-color="success">
          <CCardHeader>ກະລຸນາປ້ອນຂໍ້ມູນ</CCardHeader>
          <CCardBody>
            <div v-if="step1 == false">
              <ValidationObserver v-slot="{ invalid }">
                <CForm ref="form" :model="form" @submit.prevent="handlCheckInfo">
                  <CRow>
                    <CCol sm="12">
                      <validation-provider rules="required">
                        <div class="form-group">
                          <label>ເລືອກຈາກ :</label>
                          <v-select
                            prop="select"
                            v-model="form.select"
                            :options="selectList"
                            :reduce="code => code.code"
                            label="message"
                          />
                        </div>
                      </validation-provider>
                    </CCol>
                    <CCol sm="12" v-if="form.select == 1">
                      <div class="form-group">
                        <label>ເລກທີໃບສັນຍາບໍລິການ :</label>
                        <v-select
                          prop="select_no"
                          v-model="form.select_no"
                          :options="contract"
                          :reduce="cont_no => cont_no.cont_no"
                          label="cont_no"
                        />
                      </div>
                    </CCol>
                    <CCol sm="12" v-if="form.select == 2">
                      <div class="form-group">
                        <label>ເລກທີໃບສະເໜີລາຄາ :</label>
                        <v-select
                          prop="select_no"
                          v-model="form.select_no"
                          :options="proposal"
                          :reduce="pps_no => pps_no.pps_no"
                          label="pps_no"
                        />
                      </div>
                    </CCol>
                    <CCol sm="12" v-if="form.select == 3">
                      <CInput label="ເລກທີ່ໃບສະເໜີ :" prop="select_no" v-model="form.select_no" />
                    </CCol>
                    <CCol sm="12">
                      <CButton type="submit" :disabled="invalid" color="info" class="float-right">
                        <CIcon name="cil-check" />ຕົກລົງ
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </ValidationObserver>
            </div>
            <div v-if="step1 == true">
              <ValidationObserver v-slot="{ invalid }">
                <CForm ref="form" :model="form" @submit.prevent="handleAdd">
                  <CRow>
                    <CCol sm="12">
                      <validation-provider rules="required">
                        <div class="form-group">
                          <label>ເລກ Serial :</label>
                          <v-select
                            prop="appl_serial"
                            v-model="form.appl_serial"
                            :options="appl"
                            :reduce="appl_serial => appl_serial.appl_serial"
                            label="appl_serial"
                          />
                        </div>
                      </validation-provider>
                    </CCol>
                    <CCol sm="12">
                      <validation-provider rules="required">
                        <CInput
                          label="ຈໍານວນ :"
                          type="number"
                          prop="add_amount"
                          v-model="form.add_amount"
                        />
                      </validation-provider>
                    </CCol>
                    <CCol sm="12">
                      <validation-provider rules="required">
                        <CInput
                          label="ລະຫັດ (PASSWORD) ອຸປະກອນລູກຄ້າ :"
                          prop="appl_remark"
                          v-model="form.appl_remark"
                        />
                      </validation-provider>
                    </CCol>
                    <CCol sm="12">
                      <CButton type="submit" :disabled="invalid" color="info" class="float-right">
                        <CIcon name="cil-check" />ຕົກລົງ
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </ValidationObserver>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="8">
        <CRow>
          <CCol sm="12">ເບີກອຸປະກອນສໍາລັບເລກທີ : {{form.select_no}}</CCol>
          <CCol sm="12">
            <br />
          </CCol>
          <CCol sm="12">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">ລດ</th>
                  <th scope="col">ເລກ Serial</th>
                  <th scope="col">ຈໍານວນ</th>
                  <th scope="col">ຜູ້ເພີ້ມ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in applAddList" :key="row.index">
                  <th>{{row.value.index}}</th>
                  <th>{{row.value.appl_serial}} ( {{row.value.appl_remark}} )</th>
                  <th class="float-right">{{row.value.add_amount | numFormat}}</th>
                  <th>{{row.value.add_by}}</th>
                </tr>
              </tbody>
            </table>
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="12">
        <div class="float-right">
          <CForm ref="form" :model="form" @submit.prevent="handleSubmit">
            <CButton type="submit" variant="outline" color="primary">
              <CIcon name="cil-save" />ບັນທຶກ
            </CButton>
            <CButton @click="handleCancle" variant="outline" color="warning">
              <CIcon name="cil-x" />ຍົກເລີກ
            </CButton>
          </CForm>
        </div>
      </CCol>
    </CRow>
  </div>
</template>
 <script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { getInvoiceReceiptByIdAPI } from "@/api/invoiceReceipt";
import { mapActions } from "vuex";
import { decrypt } from "@/libs/crypto";
export default {
  name: "ApplExpendFormCreate",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    appl: {
      type: Array,
      required: true,
    },
    contract: {
      type: Array,
      required: true,
    },
    proposal: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      step1: false,
      form: {
        select: "",
        select_no: "",
        appl_serial: "",
        add_amount: "1",
        appl_remark: "",
      },
      selectList: [
        { code: 1, message: "ເບີກຕາມສັນຍາບໍລິການ" },
        { code: 2, message: "ເບີກຕາມໃບສະເໜີລາຄາ" },
        { code: 3, message: "ເບີກຕາມໃບສະເໜີອື່ນໆ" },
      ],
      applAddList: [],
    };
  },
  methods: {
    ...mapActions("applExpend", ["applianceAdd"]),
    handlCheckInfo() {
      this.step1 = true;
    },
    handleAdd() {
      let appl_serial = this.form.appl_serial;
      let add_amount = this.form.add_amount;
      let appl_remark = this.form.appl_remark;
      let add_by = decrypt(this.$store.getters.getFullName);
      this.applianceAdd({
        appl_serial,
        add_amount,
        appl_remark,
        add_by,
      })
        .then((response) => {
          // Refresh data
          this.applAddList = this.$store.getters.getApplExpend;
          // Reset data
          this.form.appl_serial = "";
          this.form.add_amount = "1";
          this.form.appl_remark = "";
        })
        .catch((error) => {
          this.$awn.alert("Add Error, Please check and try again");
        });
    },
    handleCancle() {
      this.$emit("on_cancle", {});
      this.step1 = false;
      this.form.select = "";
      this.form.select_no = "";
      this.form.appl_serial = "";
      this.form.add_amount = "1";
      this.form.appl_remark = "";
      this.applAddList = [];
    },
    handleSubmit() {
      this.$emit("on_success", {
        select_no: this.form.select_no,
        applAddList: this.applAddList,
      });
      this.step1 = false;
      this.form.select = "";
      this.form.select_no = "";
      this.form.appl_serial = "";
      this.form.add_amount = "1";
      this.form.appl_remark = "";
      this.applAddList = [];
    },
  },
};
</script>