<template>
  <div v-if="userGroup == 'administrator' || userGroup == 'manage'">
    <CCard>
      <CCardHeader>
        <strong>ຂໍ້ມູນປະຫວັດການເບີກອຸປະກອນ</strong>
        <CButton @click="modalCreate = true" variant="outline" color="primary" class="float-right">
          <CIcon name="cil-plus" />ເບິກອຸປະກອນ
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CDataTable
              :items="items"
              :fields="fields"
              :loading="loading"
              hover
              sorter
              :table-filter="{placeholder: 'ຄົ້ນຫາ...', label: ' ', align: 'right'}"
              :items-per-page="20"
              pagination
            >
              <template #actions="row">
                <td>
                  <CDropdown
                    size="sm"
                    toggler-text="ຈັດການ"
                    color="dark"
                    class="m-0 d-inline-block"
                  >
                    <CDropdownItem :disabled="userGroup != 'administrator'" v-on:click="onClickDelete(row.item)">ລົບ</CDropdownItem>
                  </CDropdown>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <!-- modal create new document out -->
    <CModal title="ຟອມເພີ້ມເບີກອຸປະກອນ" size="lg" :show.sync="modalCreate" :closeOnBackdrop="false">
      <applExpendFormCreate
        :appl="applList"
        :contract="contractList"
        :proposal="proposalList"
        @on_success="onSubmitCreate"
        @on_cancle="onSubmitCancle"
      />
      <template #footer>
        <div></div>
      </template>
    </CModal>
  </div>
</template>
<script>
import { getApplExpendAPI, deleteApplExpendAPI } from "@/api/applianceExpend";
import { getContractAPI } from "@/api/contract";
import { getProposalAPI } from "@/api/proposal";
import { getApplianceAPI } from "@/api/appliance";
import applExpendFormCreate from "./components/ApplExpendFormCreate";
import { decrypt } from "@/libs/crypto";
import { mapActions } from "vuex";
export default {
  name: "ApplExpend",
  components: {
    applExpendFormCreate,
  },
  data() {
    return {
      userGroup: decrypt(this.$store.getters.getUserGroup),
      loading: true,
      modalCreate: false,
      items: [],
      fields: [
        { key: "id", label: "ລໍາດັບ" },
        { key: "appl_date", label: "ວັນທີ່ເບິກ" },
        { key: "appl_serial", label: "Serial" },
        { key: "appl_max_add", label: "Max Address" },
        { key: "appl_name", label: "ຊື່ອຸປະກອນ" },
        { key: "appl_amount", label: "ຈໍານວນ" },
        { key: "appl_for", label: "ເບີກຕາມເລກທີ" },
        { key: "appl_m_by", label: "ບັນທຶກການເບີກ" },
        { key: "actions", label: "ຈັດການ" },
      ],
      applList: [],
      contractList: [],
      proposalList: [],
    };
  },
  mounted() {
    this.initialGetData();
  },
  methods: {
    ...mapActions("applExpend", ["createApplExpend", "clearApplExpend"]),
    // Load data  document income
    initialGetData() {
      setTimeout(() => {
        this.loading = false;
      }, 5000);
      getApplExpendAPI().then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
      getApplianceAPI().then((response) => {
        this.applList = response.data.data;
      });
      getContractAPI().then((response) => {
        this.contractList = response.data.data;
      });
      getProposalAPI().then((response) => {
        this.proposalList = response.data.data;
      });
    },
    // Create New document income
    onSubmitCreate(credentials) {
      this.createApplExpend(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalCreate = false;
    },

    onSubmitCancle() {
      this.clearApplExpend().then((response) => {
        this.initialGetData();
      });
      this.modalCreate = false;
    },
    onClickDelete(item) {
      let onOk = () => {
        deleteApplExpendAPI(item.id)
          .then((response) => {
            this.$awn.success(response.data.message);
            this.initialGetData();
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
          });
      };
      this.$awn.confirm("ເຈົ້າຕ້ອງການລົບຂໍ້ມູນນີ້ແທ້ ຫຼີ ບໍ່ ?", onOk);
    },
  },
};
</script>
